/**
 * LipidCompass Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Study } from './study';
import { User } from './user';
import { FileResource } from './fileResource';


export interface Submission { 
    id?: string;
    arangoId?: string;
    transactionUuid: string;
    visibility?: Submission.VisibilityEnum;
    status?: Submission.StatusEnum;
    submittedFiles?: Array<FileResource>;
    storageBucket?: string;
    study?: Study;
    submitter?: User;
    privateLinkUuid?: string;
    revision?: string;
    dateCreated?: string;
    dateLastModified?: string;
    createdBy?: string;
    updatedBy?: string;
}
export namespace Submission {
    export type VisibilityEnum = 'PRIVATE' | 'RESTRICTED' | 'PUBLIC' | '11184809';
    export const VisibilityEnum = {
        PRIVATE: 'PRIVATE' as VisibilityEnum,
        RESTRICTED: 'RESTRICTED' as VisibilityEnum,
        PUBLIC: 'PUBLIC' as VisibilityEnum,
        unknown_default_open_api: '11184809' as VisibilityEnum
    };
    export type StatusEnum = 'IN_PROGRESS' | 'SUBMITTED' | 'IN_CURATION' | 'IN_REVIEW' | 'PUBLISHED' | '11184809';
    export const StatusEnum = {
        IN_PROGRESS: 'IN_PROGRESS' as StatusEnum,
        SUBMITTED: 'SUBMITTED' as StatusEnum,
        IN_CURATION: 'IN_CURATION' as StatusEnum,
        IN_REVIEW: 'IN_REVIEW' as StatusEnum,
        PUBLISHED: 'PUBLISHED' as StatusEnum,
        unknown_default_open_api: '11184809' as StatusEnum
    };
}


