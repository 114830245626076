<!-- <div class="p-col-12">
  <p-steps [model]="dataSubmitSteps" [activeIndex]="activeIndex"></p-steps>
</div> -->
<p-card>
  <ng-template pTemplate="title"> Submit your study and data </ng-template>
  <ng-template pTemplate="subtitle">
    Please review your data carefully
  </ng-template>
  <ng-template pTemplate="content">
    <div class="p-fluid">
      <!-- <div class="p-field">
              <label for="firstname">Firstname</label>
              <input #firstname="ngModel" id="firstname" type="text" required pInputText [(ngModel)]="personalInformation.firstname" [ngClass]="{'ng-dirty': (firstname.invalid && submitted) || (firstname.dirty && firstname.invalid)}"> 
              <small *ngIf="(firstname.invalid && submitted) || (firstname.dirty && firstname.invalid)" class="p-error">Firstname is required.</small>
          </div>
          <div class="p-field">
              <label for="lastname">Lastname</label>
              <input #lastname="ngModel" id="lastname" type="text" required pInputText [(ngModel)]="personalInformation.lastname" [ngClass]="{'ng-dirty': (lastname.invalid && submitted) || (lastname.dirty && lastname.invalid)}"> 
              <small class="p-error" *ngIf="(lastname.invalid && submitted )|| (lastname.dirty && lastname.invalid)">Lastname is required.</small>
          </div>
          <div class="p-field">
              <label for="age">Age</label>
              <input #age="ngModel" id="age" type="number" required pInputText [(ngModel)]="personalInformation.age" [ngClass]="{'ng-dirty': (age.invalid && submitted) || (age.dirty && age.invalid)}"> 
              <small class="p-error" *ngIf="(age.invalid && submitted) || (age.dirty && age.invalid)">Age is required.</small>
          </div> -->
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="p-d-flex p-jc-between" *ngIf="submission && (submission.status === 'IN_PROGRESS')">
      <div class="p-nogutter p-justify-start">
        <button
          pButton
          type="button"
          label="Back"
          (click)="previousPage($event)"
          icon="fa fa-chevron-left"
          iconPos="left"
        ></button>
      </div>
      <div class="p-nogutter p-justify-end">
        <span class="p-buttonset">
          <button
            pButton
            type="button"
            label="Cancel"
            (click)="cancel($event)"
            icon="fa fa-times"
            iconPos="right"
            class="p-button-danger"
          ></button>
          <button
            pButton
            type="button"
            label="Submit"
            (click)="nextPage($event)"
            icon="fas fa-cloud-upload-alt"
            iconPos="right"
            class="p-button-success"
          ></button>
        </span>
      </div>
    </div>
  </ng-template>
</p-card>
