<div class="p-grid p-p-1 p-m-1">
  <p-confirmDialog
    #cd
    header="Confirmation"
    icon="fas fa-exclamation-triangle"
    rejectIcon="fas fa-times p-button-warning"
    rejectButtonStyleClass="p-button-danger"
    acceptIcon="fas fa-check"
    acceptButtonStyleClass="p-button-success"
  >
  </p-confirmDialog>
  <div class="p-col-12">
    <p-table
      #ads
      dataKey="id"
      [value]="pagedItems"
      [lazy]="true"
      [columns]="columns"
      (onLazyLoad)="loadItems($event)"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[25, 50, 100]"
      [loading]="loading"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [paginator]="true"
      [rows]="pageable.size"
      [totalRecords]="totalElements"
      [globalFilterFields]="filterFields"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [globalFilterFields]="filterFields"
      sortMode="multiple"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <button
            type="button"
            pButton
            pRipple
            icon="fas fa-file-csv"
            (click)="ads.exportCSV()"
            class="p-mr-2"
            pTooltip="CSV"
            tooltipPosition="bottom"
          ></button>
          <button
            type="button"
            pButton
            pRipple
            icon="fas fa-file-excel"
            (click)="exportExcel()"
            class="p-button-success p-mr-2"
            pTooltip="XLS"
            tooltipPosition="bottom"
          ></button>
          <!--      <button
        type="button"
        pButton
        pRipple
        icon="fas fa-file-csv"
        (click)="dt1.exportCSV({ selectionOnly: true })"
        class="p-button-info p-ml-auto"
        pTooltip="Selection Only"
        tooltipPosition="bottom"
      ></button>-->
          <span class="p-input-icon-left p-ml-auto">
            <i class="fas fa-search"></i>
            <input
              pInputText
              type="text"
              (input)="applyFilterGlobal($event, 'contains')"
              placeholder="Search"
            />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <!--<p-tableHeaderCheckbox></p-tableHeaderCheckbox>-->
            Row
          </th>

          <th
            *ngFor="let column of columns"
            pResizableColumn
            [pSortableColumn]="column.field"
          >
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ column.header }}
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </div>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-rowIndex="rowIndex"
      >
        <!--<tr class="p-selectable-row">-->
        <tr>
          <td style="width: 3rem">
            {{ rowIndex + 1 }}
            <!--<p-tableCheckbox [value]="rowData"></p-tableCheckbox>-->
          </td>
          <td *ngFor="let col of columns">
            <div *ngIf="col.filterType === 'object'">
              {{ col.content(rowData[col.field]) | json }}
            </div>
            <div *ngIf="col.filterType === 'numeric'">
              {{ col.content(rowData[col.field]) }}
            </div>
            <div *ngIf="col.filterType === 'date'">
              {{ col.content(rowData[col.field]) | date: "short" }}
            </div>
            <div *ngIf="col.filterType === 'text'">
              {{ col.content(rowData[col.field]) }}
            </div>
          </td>
          <td>
            <button
              pButton
              icon="fas fa-edit"
              class="p-button p-button-primary mr-2"
              (click)="edit(rowData)"
            ></button>
            <button
              pButton
              icon="fas fa-times"
              class="p-button p-button-danger mr-2"
              (click)="delete(rowData)"
            ></button>
            <button
              pButton
              icon="fas fa-check"
              class="p-button p-button-success"
              (click)="import(rowData)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="columns.length + 1">No Items found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
