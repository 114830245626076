<div class="p-grid">
  <div class="container p-col-12">
    <div class="lipid-search p-grid">
      <div class="p-col-12">
        <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate class="p-fluid">
          <div class="p-col-3 p-field p-grid">
            <div class="p-inputgroup">
              <p-autoComplete
                [(ngModel)]="query"
                [suggestions]="autoCompleteResults"
                (completeMethod)="autocompleteSearch($event)"
                [dropdown]="false"
                name="lipidSearchAutocomplete"
                class="p-autocomplete p-component"
                placeholder="Type to search"
                [autoHighlight]="true"
                [autofocus]="true"
                (keyup.enter)="onEnter(f)"
              ></p-autoComplete>
              <button
                pButton
                type="button"
                icon="fas fa-search"
                (click)="onSubmit(f)"
                [class.disabled]="disabled"
                class="p-button p-component"
              ></button>
            </div>
          </div>

          <div class="p-col-3 p-field p-grid">
            <div class="form-group">
              <p-dropdown
                [style]="{ width: '250px' }"
                [options]="level"
                [(ngModel)]="selectedLevel"
                [filter]="true"
                name="lipidQuantityLevelInput"
                [placeholder]="placeholder"
                id="lipidQuantityLevelInput"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="p-col-3 p-field p-grid">
            <div class="form-group">
              <p-dropdown
                [style]="{ width: '250px' }"
                [options]="matchingModes"
                [(ngModel)]="matchingMode"
                [filter]="true"
                name="matchingModeInput"
                [placeholder]="placeholder"
                id="matchingModeInput"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="p-col-3 p-field p-grid">
            <div class="form-group">
              <p-checkbox
                name="normalizeName"
                label="Normalize Name"
                [(ngModel)]="normalizeName"
                [binary]="true"
              >
              </p-checkbox>
            </div>
          </div>
          <!-- <div class="form-group p- p-field p-grid">
                        <button pButton type="button" icon="fas fa-search" (click)="onSubmit(f)"
                            [class.disabled]="disabled"></button>
                        <span [class]="loading?'loader':''"></span>
                    </div> -->
        </form>
      </div>
      <div class="p-col-12">
        <p-table
          [value]="results"
          [rows]="20"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="rowsPerPageOptions"
          [totalRecords]="page.totalElements"
          [class.disabled]="disabled"
          [loading]="loading"
          [lazy]="true"
          (onLazyLoad)="loadQueryPage($event)"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Lipid Quantities
              <!-- <p-button icon="pi pi-refresh"></p-button> -->
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Row</th>
              <th>Link</th>
              <th>Name</th>
              <th>Level</th>
              <th>Identified In</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Reliability</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-lipidQuantity
            let-rowIndex="rowIndex"
          >
            <tr>
              <td>{{ rowIndex + 1 }}</td>
              <td>
                <a
                  [routerLink]="[
                    '/lipid/' + lipidQuantity.lipidQuantity.lipid['id']
                  ]"
                  >{{ lipidQuantity["name"] }}</a
                >
              </td>
              <td>{{ lipidQuantity?.name }}</td>
              <td>
                <span>{{
                  lipidQuantity.lipidQuantity["lipid"]["lipidLevel"]
                    | uppercase
                    | translate
                }}</span>
              </td>
              <td>
                <a
                  [routerLink]="[
                    '/mztab/' + lipidQuantity.lipidQuantity['mzTabResultId']
                  ]"
                  >{{ lipidQuantity.lipidQuantity.mzTabResultId }}</a
                >
              </td>
              <td>
                <span>{{ lipidQuantity.lipidQuantity.assayQuantity }}</span>
              </td>
              <!-- <td><span>{{lipidQuantity.lipidQuantity.quantificationUnit.name}}</span></td> -->
              <td><span>[pmol/L]</span></td>
              <td>
                <span
                  >MSI Level
                  {{
                    lipidQuantity.lipidQuantity.identificationReliability
                  }}</span
                >
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
              Found {{ page ? page.totalElements : 0 }} lipid quantities.
            </div>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="6">No records found</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
