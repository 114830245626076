<div class="flex flex-column">
  <section class="m-4">
    <h1>About LipidCompass</h1>
    <p>LipidCompass is a database to help you navigate and explore the lipid quantitative and structural space.</p>
    <h2></h2>
    <p></p>
    <h2></h2>
    <h2>Contributors</h2>
    <sup>Please see individual projects or datasets for a list of data
      contributors.</sup>
    <hr class="light-hr" />
    <h2>Acknowledgements</h2>
    <p>We would like to thank the following resources and initiatives for their support:</p>
    <ul>
      <li>
        <a href="https://lipidmaps.org" target="_blank">LIPID MAPS - Lipid Maps Structure Database</a>
      </li>
      <li>
        <a href="https://swisslipids.org" target="_blank">SwissLipids - SIB Lipid Structure Database</a>
      </li>
      <li>
        <a href="https://lipidomics-standards-initiative.org/" target="_blank">Lipidomics Standard Initiative (LSI)</a>
      </li>
      <li>
        <a href="https://lipidomicssociety.org/" target="_blank">International Lipidomics Society</a>
      </li>
    </ul>
  </section>
</div>