<footer class="footer" style="background-color: #ffffff">
    <hr class="light-hr mt-3 mb-3" />
    <div class="flex flex-row justify-content-between flex-wrap">
        <figure class="flex align-items-center justify-content-center">
            <a href="https://lifs-tools.org" target="_blank"><img
                    src="assets/img/LIFS-signet-2017x03x13-transp-small.png" height="50px" class="figure-img"
                    alt="LIFS Logo" /></a>
        </figure>

        <figure class="flex align-items-center justify-content-center">
            <a href="https://fz-borstel.de/index.php/en/" target="_blank"><img src="assets/img/fz-borstel-en.jpg"
                    height="50px" class="figure-img" alt="FZ Borstel Logo" /></a>
        </figure>

        <figure class="flex align-items-center justify-content-center">
            <a href="https://www.mpi-cbg.de/home/" target="_blank"><img src="assets/img/mpi-logo.png" height="50px"
                    class="figure-img" alt="MPI CBG Logo" /></a>
        </figure>

        <figure class="flex align-items-center justify-content-center">
            <a href="https://www.univie.ac.at/" target="_blank"><img src="assets/img/uni-wien-logo.jpg" height="50px"
                    class="figure-img" alt="Uni Wien Logo" /></a>
        </figure>

        <figure class="flex align-items-center justify-content-center">
            <a href="https://www.fz-juelich.de/" target="_blank"><img src="assets/img/fz-juelich-logo.png" height="50px"
                    class="figure-img" alt="Forschungszentrum Jülich Logo" /></a>
        </figure>
    </div>
    <hr class="light-hr mb-3" />
    <div class="flex flex-row justify-content-between flex-wrap">
        <ul class="imprint-links">
            <li>
                <span class="mr-2">&copy;</span><span class="mr-2">{{ copyrightDate | date: "yyyy" }}</span><a
                    href="https://lifs-tools.org">LIFS</a>
            </li>
            <li>
                <a href="https://lifs-tools.org/imprint-privacy-policy.html">Imprint & Data Protection</a>
            </li>
            <li>
                <a href="https://lifs-tools.org/terms-of-service.html">Terms of Service</a>
            </li>
        </ul>
    </div>
    <hr class="light-hr mb-3" />
    <div class="flex flex-row justify-content-end flex-wrap">
        <figure class="mb-2 mr-2">
            <a href="https://www.denbi.de/" target="_blank"><img src="assets/img/deNBI_logo.jpg" height="50px"
                    class="figure-img" alt="de.NBI Logo" /></a>
            <figcaption class="small">Hosted on the de.NBI Cloud</figcaption>
        </figure>
    </div>
</footer>