<p-card>
    <ng-template pTemplate="header"> </ng-template>
    <div class="p-card-content py-0">
        <div class="flex justify-content-start flex-row flex-wrap">
            <!-- logo -->
            <div class="flex-auto flex-grow-0 flex flex-column">
                <div class="layered-image" style="width: 200px; height: 200px">
                    <img
                        class="image-base"
                        width="200"
                        height="200"
                        src="assets/img/lipidcompass-base.png"
                        alt="LipidCompass Logo"
                    />
                    <img
                        class="image-overlay"
                        (click)="rotate()"
                        [@rotatedState]="state"
                        width="200"
                        height="200"
                        src="assets/img/lipidcompass-rose.png"
                        alt="Lipid Compass Logo"
                    />
                </div>
            </div>
            <!-- title -->
            <div class="flex-auto flex-grow-0 flex px-6 max-w-30rem flex-column">
                <div class="grid">
                    <div *ngIf="this.loadingStats && !this.dbStats" class="md:col-12 lg:col-12 xl:col-12 sm:col-12">
                        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
                    </div>
                    <div *ngIf="!this.loadingStats" class="md:col-12 lg:col-12 xl:col-12 sm:col-12">
                        <div class="p-card-title">LipidCompass</div>
                        <div class="p-card-subtitle" *ngIf="this.dbStats">
                            {{ this.dbStats.releaseVersion }}&nbsp;<span>{{
                                this.dbStats.releaseDate | date: "mediumDate":"en-US"
                            }}</span>
                        </div>
                        <div class="p-card-content">
                            <p *ngIf="this.dbStats">
                                A database to help you navigate and explore the lipid
                                structural space of
                                {{
                                    this.dbStats.lipidQuantities
                                        | number: "1.0-0":"en-US"
                                }}
                                lipid quantities within and across studies.
                            </p>
                            <p *ngIf="!this.dbStats">
                                No data available.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-1 flex-grow-1 flex-wrap flex-column flex">
                <!-- <div class="flex flex-row"> -->
                    <!-- <div *ngIf="this.loadingStats && !this.dbStats" class="md:col-12 lg:col-12 xl:col-12 sm:col-12">
                        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
                    </div> -->
                    <app-stats-card-grid *ngIf="!this.loadingStats" [cards]="dbStatsCards" class="flex-auto flex-wrap flex-grow-1 flex"></app-stats-card-grid>
                <!-- </div> -->
                <!-- DB stats -->
                <!-- <div class="grid">
                    <div *ngIf="this.loadingStats && !this.dbStats" class="md:col-12 lg:col-12 xl:col-12 sm:col-12">
                        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
                    </div>
                    <div *ngIf="!this.loadingStats"
                        class="md:col-6 lg:col-6 xl:col-6 sm:col-12"
                        [ngStyle]="{
                            'background-color': 'var(--surface-100)'
                        }"
                    >
                        <table *ngIf="this.dbStats && !this.loadingStats">
                            <tr>
                                <td>Lipid Categories</td>
                                <td>
                                    {{
                                        this.dbStats.categories
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Lipid Classes</td>
                                <td>
                                    {{
                                        this.dbStats.classes
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Lipid Species</td>
                                <td>
                                    {{
                                        this.dbStats.species
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Lipid Mol. Species</td>
                                <td>
                                    {{
                                        this.dbStats.molecularSpecies
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Lipid SN Position</td>
                                <td>
                                    {{
                                        this.dbStats.snPosition
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Lipid Structure Defined</td>
                                <td>
                                    {{
                                        this.dbStats.structureDefined
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Lipid Full Structure</td>
                                <td>
                                    {{
                                        this.dbStats.fullStructure
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Lipid Complete Structure</td>
                                <td>
                                    {{
                                        this.dbStats.completeStructure
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div
                        class="md:col-6 lg:col-6 xl:col-6 sm:col-12"
                        [ngStyle]="{
                            'background-color': 'var(--surface-100)'
                        }"
                    >
                        <table *ngIf="this.dbStats">
                            <tr>
                                <td>
                                    <abbr
                                        title="A scientific experiment comprised of one or more datasets to prove a hypothesis"
                                        >Studies</abbr
                                    >
                                </td>
                                <td>
                                    <a [routerLink]="['/study']">{{
                                        this.dbStats.experiments
                                            | number: "1.0-0":"en-US"
                                    }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <abbr
                                        title="A report of identified and quantified lipids"
                                        >Datasets</abbr
                                    >
                                </td>
                                <td>
                                    <a [routerLink]="['/dataset']">{{
                                        this.dbStats.results
                                            | number: "1.0-0":"en-US"
                                    }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <abbr title="Biological species or organism"
                                        >Biological Species</abbr
                                    >
                                </td>
                                <td>
                                    {{
                                        this.dbStats.taxonomicSpecies
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <abbr title="Biological tissue"
                                        >Tissues</abbr
                                    >
                                </td>
                                <td>
                                    {{
                                        this.dbStats.tissues
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <abbr title="Cell types">Cell Types</abbr>
                                </td>
                                <td>
                                    {{
                                        this.dbStats.cellTypes
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <abbr title="Organismal disease"
                                        >Diseases</abbr
                                    >
                                </td>
                                <td>
                                    {{
                                        this.dbStats.diseases
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <abbr title="Study variables"
                                        >Study Variables</abbr
                                    >
                                </td>
                                <td>
                                    {{
                                        this.dbStats.studyVariableFactors
                                            | number: "1.0-0":"en-US"
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</p-card>

<!-- <p-card>
    <div>
        <div *ngIf="this.loadingStats && !this.dbStats" class="md:col-12 lg:col-12 xl:col-12 sm:col-12">
            <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
        </div>
        <app-stats-card-grid *ngIf="!this.loadingStats" [cards]="dbStatsCards" ></app-stats-card-grid>
    </div>
</p-card> -->